import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Push Press to a 1RM`}</p>
    <p>{`Jerk (Push or Split) to a 1RM`}</p>
    <p>{`Max Strict Pullups, 1 attempt.`}</p>
    <p>{`then,`}</p>
    <p>{`1000M Row for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Monday is our FREE Labor Day wod at 10:30am.  All other
classes and open gym time is cancelled for the day.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your team registered for the Granite Games Fall Throwdown here
at The Ville September 14th!  Only 7 spots left!  Learn more about the
Throwdown here:
 `}<a parentName="em" {...{
            "href": "https://thegranitegames.com/about-throwdowns/"
          }}>{`https://thegranitegames.com/about-throwdowns/   `}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      